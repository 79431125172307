import React, { useState } from "react";
import "../../../Form/Form.css"; // Ensure this CSS file is created and linked properly

const DeleteUserForm = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate if email is provided
    if (!email) {
      setError("Please enter an email address!");
      return;
    }

    setError("");
    setSuccessMessage(""); // Clear any previous success message

    try {
      // Make an actual API call to delete the user by email
      await deleteUserByEmail(email);
    } catch (err) {
      setError("An error occurred while deleting the user. Please try again.");
    }
  };

  const deleteUserByEmail = async (email) => {
    const apiUrl = process.env.REACT_APP_API_URL; // API URL from environment variables

    const response = await fetch(`${apiUrl}/api/v1/admin/user/delete-user`, {
      method: 'DELETE', // Use DELETE method to delete the user
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`, // Add token to the Authorization header if needed
      },
      body: JSON.stringify({ email }), // Send email to the server for deletion
    });

    if (!response.ok) {
      throw new Error('Failed to delete user. Please try again.');
    }

    const result = await response.json();

    if (result.success) {
      setSuccessMessage("User successfully deleted!");
      setEmail(""); // Reset email input
    } else {
      setError(result.message || "An error occurred during deletion.");
    }
  };

  return (
    <div className="form-to-pdf">
      <h2>Delete User</h2>
      <form onSubmit={handleSubmit}>
        <label>Email:</label>
        <input
          type="email"
          name="email"
          value={email}
          onChange={handleChange}
          required
        />

        {error && <p className="error-message">{error}</p>}
        {successMessage && <p className="success-message">{successMessage}</p>}

        <button type="submit" className="submit-button">
          Delete User
        </button>
      </form>
    </div>
  );
};

export default DeleteUserForm;
