import React, { useState,useEffect } from "react";
import Select from "react-select";
import { useNavigate } from "react-router-dom"; 
import "./Form.css"; // Ensure this CSS file is created and linked properly
import PopBox from "../components/Popupbox"; 
import Loader from "../components/Loader";
const resortOptions = [
  { value: "RAMATAN RESORT (VAGATOR) DELUX GARDEN VIEW", label: "RAMATAN RESORT (VAGATOR) DELUX GARDEN VIEW" },
  { value: "RAMATAN RESORT (VAGATOR) SUPER DELUX WITH BALCONEY", label: "RAMATAN RESORT (VAGATOR) SUPER DELUX WITH BALCONEY" },
  { value: "RAMATAN RESORT (VAGATOR) PREMIUM WITH BATH TUB", label: "RAMATAN RESORT (VAGATOR) PREMIUM WITH BATH TUB" },
  { value: "THE OCEAN PARK EXECUTIVE ROOM", label: "THE OCEAN PARK EXECUTIVE ROOM" },
  { value: "THE OCEAN PARK STUDIO ROOM", label: "THE OCEAN PARK STUDIO ROOM" },
  { value: "THE BAGA MARINA STANDARD ROOM", label: "THE BAGA MARINA STANDARD ROOM" },
  { value: "THE BAGA MARINA SUPERIOR DOUBLE", label: "THE BAGA MARINA SUPERIOR DOUBLE" },
  { value: "THE BAGA MARINA DELUXE DOUBLE", label: "THE BAGA MARINA DELUXE DOUBLE" },
  { value: "D ALTRUS GRAND PREMIUM", label: "D ALTRUS GRAND PREMIUM" },
  { value: "MERADAN LA OASIS DELUX ROOM", label: "MERADAN LA OASIS DELUX ROOM" },
  { value: "MERADAN OPUS DELUX ROOM", label: "MERADAN OPUS DELUX ROOM" },
  { value: "CALUNGUT GRAND DELUX ROOM", label: "CALUNGUT GRAND DELUX ROOM" },
  { value: "AMANI VAGATOR DELUX ROOM", label: "AMANI VAGATOR DELUX ROOM" },
  { value: "JOIEA DO MAR PREMIUM ROOM", label: "JOIEA DO MAR PREMIUM ROOM" },
  { value: "19 BELOW CABANA COTTAGE", label: "19 BELOW CABANA COTTAGE" },
  { value: "AMARA GRAND OCEAN FRONT 4* PREMIUM ROOM", label: "AMARA GRAND OCEAN FRONT 4* PREMIUM ROOM" },
  { value: "AMARA GRAND BAGA 4* DELUX", label: "AMARA GRAND BAGA 4* DELUX" },
  { value: "HOTEL GOA CONTINENTAL BAGA DELUXE ROOM", label: "HOTEL GOA CONTINENTAL BAGA DELUXE ROOM" },
  { value: "HOTEL GOA CONTINENTAL BAGA EXECUTIVE ROOM", label: "HOTEL GOA CONTINENTAL BAGA EXECUTIVE ROOM" },
  { value: "HOTEL GOA CONTINENTAL BAGA FAMILY SUITE (4PAX)", label: "HOTEL GOA CONTINENTAL BAGA FAMILY SUITE (4PAX)" },
  { value: "CITRUS CANDOLIM CLASSIC ROOM", label: "CITRUS CANDOLIM CLASSIC ROOM" },
  { value: "SILVER SHELL SUPERIOR ROOM", label: "SILVER SHELL SUPERIOR ROOM" },
  { value: "SILVER SHELL CLASSIC ROOM", label: "SILVER SHELL CLASSIC ROOM" },
  { value: "SILVER SHELL PREMIUM ROOM", label: "SILVER SHELL PREMIUM ROOM" },
  { value: "LILLY WOOD HIGHLAND & BEACH RESORT EXECUTIVE ROOM", label: "LILLY WOOD HIGHLAND & BEACH RESORT EXECUTIVE ROOM" },
  { value: "LILLY WOOD HIGHLAND & BEACH RESORT AMBASSADOR ROOM", label: "LILLY WOOD HIGHLAND & BEACH RESORT AMBASSADOR ROOM" },
  { value: "GOLDEN SANDS DELUX ROOM", label: "GOLDEN SANDS DELUX ROOM" },
  { value: "GOLDEN SANDS PREMIUM ROOM", label: "GOLDEN SANDS PREMIUM ROOM" },


  
];
const activityOptions = [
  { value: "ADVENTURE PARTY BOAT", label: "ADVENTURE PARTY BOAT" },
  { value: "DINNER CRUISE", label: "DINNER CRUISE" },
  { value: "SCUBA WITH WATERSPORT AT GRAND ISLAND(SIC)", label: "SCUBA WITH WATERSPORT AT GRAND ISLAND(SIC)" },
  { value: "SCUBA WITH WATERSPORT AT MALWAN(SIC)", label: "SCUBA WITH WATERSPORT AT MALWAN(SIC)" },
  { value: "SCUBA WITH WATERSPORT AT PARADISE BEACH(SIC)", label: "SCUBA WITH WATERSPORT AT PARADISE BEACH(SIC)" },
];


const AddTripForm = () => {
  const [formData, setFormData] = useState({
    checkInDate: "",
    numberOfNights: "",
    checkOutDate: "",
    numberOfCouples: "",
    mealPlan: "",
    extraAdultWithMattress: "",
    extraChildWithoutMattress: "",
    extraChildWithMattress: "",
    vehicle: "",
    pickupFrom: "",
    dropTo: "",
    southGoaTour: "",
    northGoaTour: "",
    dudhsagarTour: "", 
    activity1: "",
    activity2: "",
    activity3: "",
    selectedResorts: [],
    addServiceCharge: false,
    serviceChargePerCouple: "",
    serviceChargePerAdult: "",
    serviceChargePerChild: "",
  });
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  useEffect(() => {
    if (formData.checkInDate && formData.numberOfNights) {
      const checkIn = new Date(formData.checkInDate);
      const checkOut = new Date(checkIn);
      checkOut.setDate(checkIn.getDate() + parseInt(formData.numberOfNights));
      setFormData((prev) => ({
        ...prev,
        checkOutDate: checkOut.toISOString().split("T")[0], // Format as YYYY-MM-DD
      }));
    }
  }, [formData.checkInDate, formData.numberOfNights]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleResortChange = (selectedOptions) => {
    const selectedResorts = selectedOptions.map((option) => option.value);
    setFormData((prev) => ({ ...prev, selectedResorts }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
   // Replace with your actual token
    const apiEndpoint = "admin/hotel/get-hotel-rate";
    const apiUrl = process.env.REACT_APP_API_URL; // Replace with your actual API endpoint
    const token = localStorage.getItem("token");
    // Prepare the request payload
    const payload = {
      check_in_date: formData.checkInDate,
      number_of_nights: parseInt(formData.numberOfNights),
      number_of_couples : formData.numberOfCouples,
      meal_plan: formData.mealPlan,
      resorts: formData.selectedResorts,
      extra_adult_with_mattress: parseInt(formData.extraAdultWithMattress) || 0,
      extra_child_with_mattress: parseInt(formData.extraChildWithMattress) || 0,
      extra_child_without_mattress: parseInt(formData.extraChildWithoutMattress) || 0,
      vehicle_type: formData.vehicle,
      pickup: formData.pickupFrom,
      drop: formData.dropTo,
      south_goa_tour: formData.southGoaTour,
      north_goa_tour: formData.northGoaTour,
      dudhsagar_tour: formData.dudhsagarTour,
      activity_1: formData.activity1,
      activity_2: formData.activity2,
      activity_3: formData.activity3,
    };
  
    try {
      const response = await fetch(`${apiUrl}/api/v1/admin/hotel/get-hotel-rate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });
  
      if (response.ok) {
        const responseData = await response.json();
        setSuccessMessage("Quotation Get Successfully !");
  
        // Navigate to the next page with response and form data
        navigate("/textbox", { state: {responseData, formData } });
      } else {
        const errorData = await response.json();
        setError(errorData.message || "Something went wrong");
      }
    } catch (error) {
      setError("Network error occurred");
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <div className="form-to-pdf">
       {loading && <Loader />}

      {/* Error Popup */}
      {error && <PopBox message={error} type="error" />}
      {successMessage && <PopBox message={successMessage} type="success" />}
      <h2>Goa Quotation</h2>
      <form onSubmit={handleSubmit}>
        <label>Check-in Date:</label>
        <input
          type="date"
          name="checkInDate"
          value={formData.checkInDate}
          onChange={handleChange}
          required
        />

        <label>Number of Nights:</label>
        <input
          type="number"
          name="numberOfNights"
          value={formData.numberOfNights}
          onChange={handleChange}
          min="1"
          required
        />

<label>Check-out Date:</label>
        <input
          type="date"
          name="checkOutDate"
          value={formData.checkOutDate}
          onChange={handleChange}
          disabled // Disable the checkout date field
          required
        />

        <label>Number of Couples:</label>
        <input
          type="number"
          name="numberOfCouples"
          value={formData.numberOfCouples}
          onChange={handleChange}
          min="1"
          required
        />

        <label>Meal Plan:</label>
        <select name="mealPlan" value={formData.mealPlan} onChange={handleChange} required>
          <option value="">Select</option>
          <option value="MAP">MAP</option>
          <option value="CP">CP</option>
          <option value="CUSTOM">CUSTOM</option>
        </select>

        <label>Extra Adult with Mattress:</label>
        <input
          type="number"
          name="extraAdultWithMattress"
          value={formData.extraAdultWithMattress}
          onChange={handleChange}
          min="0"
        />

        <label>Extra Child without Mattress:</label>
        <input
          type="number"
          name="extraChildWithoutMattress"
          value={formData.extraChildWithoutMattress}
          onChange={handleChange}
          min="0"
        />

        <label>Extra Child with Mattress:</label>
        <input
          type="number"
          name="extraChildWithMattress"
          value={formData.extraChildWithMattress}
          onChange={handleChange}
          min="0"
        />

        <label>Vehicle:</label>
        <select name="vehicle" value={formData.vehicle} onChange={handleChange} >
        <option value="">Select</option>
          <option value="SMALL CAR UPTO 3 PAX">SMALL CAR UPTO 3 PAX</option>
          <option value="2 SMALL CAR">2 SMALL CAR</option>
          <option value="ERTIGA (UPTO 6 PAX)">ERTIGA (UPTO 6 PAX)</option>
          <option value="WINGER 10-12">WINGER 10-12</option>
          <option value="13-17 SEATER">13-17 SEATER</option>
          <option value="18-20 SEATER">18-20 SEATER</option>
          <option value="25-27 SEATER">25-27 SEATER</option>
          <option value="30-35 SEATER">30-35 SEATER</option>
          <option value="40 SEATER BUS">40 SEATER BUS</option>
        </select>

        <label>Pickup From:</label>
        <select name="pickupFrom" value={formData.pickupFrom} onChange={handleChange} >
        <option value="">Select</option>
          <option value="THIVIM RAILWAY STATION">THIVIM RAILWAY STATION</option>
          <option value="KARMALI RAILWAY STATION">KARMALI RAILWAY STATION</option>
          <option value="MADGAON RAILWAY STATION">MADGAON RAILWAY STATION</option>
          <option value="DABOLIM AIRPORT">DABOLIM AIRPORT</option>
          <option value="MAPUSA BUS STOP">MAPUSA BUS STOP</option>
          <option value="PANJIM BUS STOP">PANJIM BUS STOP</option>
          <option value="VASCO RAILWAY STATION">VASCO RAILWAY STATION</option>
          <option value="MOPA AIRPORT">MOPA AIRPORT</option>
        </select>

        <label>Drop To:</label>
        <select name="dropTo" value={formData.dropTo} onChange={handleChange} >
        <option value="">Select</option>
          <option value="THIVIM RAILWAY STATION">THIVIM RAILWAY STATION</option>
          <option value="KARMALI RAILWAY STATION">KARMALI RAILWAY STATION</option>
          <option value="MADGAON RAILWAY STATION">MADGAON RAILWAY STATION</option>
          <option value="DABOLIM AIRPORT">DABOLIM AIRPORT</option>
          <option value="MAPUSA BUS STOP">MAPUSA BUS STOP</option>
          <option value="PANJIM BUS STOP">PANJIM BUS STOP</option>
          <option value="VASCO RAILWAY STATION">VASCO RAILWAY STATION</option>
          <option value="MOPA AIRPORT">MOPA AIRPORT</option>
        </select>

        <label>South Goa Tour:</label>
        <select name="southGoaTour" value={formData.southGoaTour} onChange={handleChange} >
          <option value="">Select</option>
          <option value="NO">NO</option>
          <option value="PVT">PVT</option>
          <option value="SIC">SIC</option>
        </select>

        <label>North Goa Tour:</label>
        <select name="northGoaTour" value={formData.northGoaTour} onChange={handleChange} >
          <option value="">Select</option>
          <option value="NO">NO</option>
          <option value="PVT">PVT</option>
          <option value="SIC">SIC</option>
        </select>

        <label>Dudhsagar Tour:</label>
        <select
          name="dudhsagarTour"
          value={formData.dudhsagarTour}
          onChange={handleChange}
          
        >
          <option value="">Select</option>
          <option value="NO">NO</option>
          <option value="PVT">PVT</option>
          <option value="SIC">SIC</option>
        </select>

        <label>Activity 1:</label>
        <select name="activity1" value={formData.activity1} onChange={handleChange}>
          <option value="">Select</option>
          {activityOptions.map((option) => (
    <option key={option.value} value={option.value}>
      {option.label}
    </option>
  ))}
        </select>

        <label>Activity 2:</label>
        <select name="activity2" value={formData.activity2} onChange={handleChange}>
          <option value="">Select</option>
          {activityOptions.map((option) => (
    <option key={option.value} value={option.value}>
      {option.label}
    </option>
  ))}
        </select>

        <label>Activity 3:</label>
        <select name="activity3" value={formData.activity3} onChange={handleChange}>
          <option value="">Select</option>
          {activityOptions.map((option) => (
    <option key={option.value} value={option.value}>
      {option.label}
    </option>
  ))}
        </select>

        <label>Resorts:</label>
        <Select
          options={resortOptions}
          isMulti
          onChange={handleResortChange}
          placeholder="Select Resorts"
        />

        {/* <label>
          <input
            type="checkbox"
            name="addServiceCharge"
            checked={formData.addServiceCharge}
            onChange={handleChange}
          />
          Add Service Charge
        </label> */}

        {/* {formData.addServiceCharge && (
          <>
            <label>Service Charge per Couple:</label>
            <input
              type="number"
              name="serviceChargePerCouple"
              value={formData.serviceChargePerCouple}
              onChange={handleChange}
              min="0"
            />

            <label>Service Charge per Adult:</label>
            <input
              type="number"
              name="serviceChargePerAdult"
              value={formData.serviceChargePerAdult}
              onChange={handleChange}
              min="0"
            />

            <label>Service Charge per Child:</label>
            <input
              type="number"
              name="serviceChargePerChild"
              value={formData.serviceChargePerChild}
              onChange={handleChange}
              min="0"
            />
          </>
        )} */}

        <button className="submit-button">Submit</button>
      </form>
    </div>
  );
};

export default AddTripForm;
