import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import "./Form.css"; // Ensure this CSS file is created and linked properly
import PopBox from "../components/Popupbox";
import Loader from "../components/Loader";

const resortOptions = [
  { value: "THE ANANTA UDAIPUR LUXURY COTTAGE", label: "THE ANANTA UDAIPUR LUXURY COTTAGE" },
  { value: "THE ANANTA UDAIPUR JUNGLE WOODEN COTTAGE", label: "THE ANANTA UDAIPUR JUNGLE WOODEN COTTAGE" },
  { value: "UDAIPUR LAKEND DELUX CITY VIEW", label: "UDAIPUR LAKEND DELUX CITY VIEW" },
  { value: "UDAIPUR LAKEND DELUX LAKE VIEW", label: "UDAIPUR LAKEND DELUX LAKE VIEW" },
  { value: "ANANDAM RESORT PREMIER ROOM", label: "ANANDAM RESORT PREMIER ROOM" },
  { value: "ANANDAM RESORT HILL VIEW SUITE BALCONY", label: "ANANDAM RESORT HILL VIEW SUITE BALCONY" },
  { value: "ANANDAM RESORT HILL VIEW JACUZZI SUITE WITH PRIVATE POOL", label: "ANANDAM RESORT HILL VIEW JACUZZI SUITE WITH PRIVATE POOL" },
  { value: "ANANDAM RESORT HILL VIEW ANANDAM POOL VILLA JACUZZI", label: "ANANDAM RESORT HILL VIEW ANANDAM POOL VILLA JACUZZI" },
  { value: "ROOP GHAR RESORT & SPA PREMIUM", label: "ROOP GHAR RESORT & SPA PREMIUM" },
  { value: "UDAIPUR HOWARD JOHNSON BY WYNDHAM DELUXE ROOM", label: "UDAIPUR HOWARD JOHNSON BY WYNDHAM DELUXE ROOM" },
  { value: "UDAIPUR HOWARD JOHNSON BY WYNDHAM PREMIUM", label: "UDAIPUR HOWARD JOHNSON BY WYNDHAM PREMIUM" },
  { value: "HUNTING LODGE PREMIUM", label: "HUNTING LODGE PREMIUM" },
  { value: "PANDORA DELUXE ROOM", label: "PANDORA DELUXE ROOM" },
  { value: "TURBAN HOTELS SUPERIOR ROOM", label: "TURBAN HOTELS SUPERIOR ROOM" },
  { value: "TURBAN HOTELS TURBAN ROOM", label: "TURBAN HOTELS TURBAN ROOM" },
  { value: "TURBAN HOTELS DELUXE ROOM", label: "TURBAN HOTELS DELUXE ROOM" },
  { value: "TURBAN HOTELS PREMIUM ROOM", label: "TURBAN HOTELS PREMIUM ROOM" },
  { value: "TURBAN HOTELS EXECUTIVE ROOM", label: "TURBAN HOTELS EXECUTIVE ROOM" },
  { value: "TURBAN HOTELS SUITE", label: "TURBAN HOTELS SUITE" },
  { value: "TURBAN HOTELS LUXURY COTTAGE", label: "TURBAN HOTELS LUXURY COTTAGE" },
  { value: "TURBAN HOTELS LUXURY COTTAGE WITH JACUZZI", label: "TURBAN HOTELS LUXURY COTTAGE WITH JACUZZI" },
  { value: "TURBAN HOTELS LUXURY COTTAGE WITH PLUNGE POOL", label: "TURBAN HOTELS LUXURY COTTAGE WITH PLUNGE POOL" },
  { value: "G MOUNT VALLEY DELUXE ROOM", label: "G MOUNT VALLEY DELUXE ROOM" },
  { value: "THE BODHI DELUXE ROOM", label: "THE BODHI DELUXE ROOM" },
  { value: "LEMON TREE SUPERIOR", label: "LEMON TREE SUPERIOR" },
  { value: "SAJJANBAGH KUMBHALGARH ARAVALLI VIEW", label: "SAJJANBAGH KUMBHALGARH ARAVALLI VIEW" },
  { value: "MAHUA BAGH DELUXE ROOM", label: "MAHUA BAGH DELUXE ROOM" },
  { value: "MAHUA BAGH PREMIUM ROOM", label: "MAHUA BAGH PREMIUM ROOM" },
  { value: "MAHUA BAGH SUPER DELUXE ROOM", label: "MAHUA BAGH SUPER DELUXE ROOM" },
  { value: "MAHUA BAGH MAHUA BAGH COTTAGE", label: "MAHUA BAGH MAHUA BAGH COTTAGE" },
  { value: "MAHUA BAGH ARAVALI COTTAGE", label: "MAHUA BAGH ARAVALI COTTAGE" },
  { value: "MAHUA BAGH MAHUA BAGH VISTA", label: "MAHUA BAGH MAHUA BAGH VISTA" },
  { value: "MAHUA BAGH POOL VILLA COTTAGE", label: "MAHUA BAGH POOL VILLA COTTAGE" },
  { value: "MAHUA BAGH HONEYMOON COTTAGE", label: "MAHUA BAGH HONEYMOON COTTAGE" },
  { value: "KANANWAS PREMIUM COTTAGE", label: "KANANWAS PREMIUM COTTAGE" },
  { value: "KANANWAS LUXURY COTTAGE", label: "KANANWAS LUXURY COTTAGE" },
  { value: "KANANWAS SWEETHEART COTTAGE", label: "KANANWAS SWEETHEART COTTAGE" },
  { value: "KANANWAS PLUNGE POOL COTTAGE", label: "KANANWAS PLUNGE POOL COTTAGE" },
  { value: "KANANWAS PRESIDENTIAL SUITE", label: "KANANWAS PRESIDENTIAL SUITE" },
  { value: "RENEST HERITAGE ROOM (7 ROOMS)", label: "RENEST HERITAGE ROOM (7 ROOMS)" },
  { value: "RENEST GRAND HERITAGE ROOM (4 ROOMS)", label: "RENEST GRAND HERITAGE ROOM (4 ROOMS)" },
  { value: "RENEST MAJESTIC ROOM (5 ROOMS)", label: "RENEST MAJESTIC ROOM (5 ROOMS)" },
  { value: "RENEST REGAL ROOM (6 ROOMS)", label: "RENEST REGAL ROOM (6 ROOMS)" },
  { value: "RENEST ARAVALI SUITE (6 ROOMS)", label: "RENEST ARAVALI SUITE (6 ROOMS)" },
  { value: "RENEST ROYAL SUITE (2 ROOMS)", label: "RENEST ROYAL SUITE (2 ROOMS)" },
  { value: "RENEST MAHARAJA SUITE (4 ROOMS)", label: "RENEST MAHARAJA SUITE (4 ROOMS)" },
  { value: "THE EVEREST HILL RESORT", label: "THE EVEREST HILL RESORT" },
];


const AddTripForm = () => {
  const [formData, setFormData] = useState({
    checkInDate: "",
    numberOfNights: "",
    checkOutDate: "",
    numberOfCouples: "",
    mealPlan: "",
    extraAdultWithMattress: "",
    extraChildWithMattress: "",
    extraChildWithoutMattress: "",
    selectedResorts: [],
  });

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  useEffect(() => {
    if (formData.checkInDate && formData.numberOfNights) {
      const checkIn = new Date(formData.checkInDate);
      const checkOut = new Date(checkIn);
      checkOut.setDate(checkIn.getDate() + parseInt(formData.numberOfNights));
      setFormData((prev) => ({
        ...prev,
        checkOutDate: checkOut.toISOString().split("T")[0],
      }));
    }
  }, [formData.checkInDate, formData.numberOfNights]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleResortChange = (selectedOptions) => {
    const selectedResorts = selectedOptions.map((option) => option.value);
    setFormData((prev) => ({ ...prev, selectedResorts }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const apiUrl = process.env.REACT_APP_API_URL; // Replace with your actual API endpoint
    const token = localStorage.getItem("token");
    const payload = {
      check_in_date: formData.checkInDate,
      number_of_nights: parseInt(formData.numberOfNights),
      number_of_couples: formData.numberOfCouples,
      meal_plan: formData.mealPlan,
      resorts: formData.selectedResorts,
      extra_adult_with_mattress: parseInt(formData.extraAdultWithMattress) || 0,
      extra_child_with_mattress: parseInt(formData.extraChildWithMattress) || 0,
      extra_child_without_mattress: parseInt(formData.extraChildWithoutMattress) || 0,
    };

    try {
      const response = await fetch(`${apiUrl}/api/v1/admin/hotel/rajasthan-get-hotel-rate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const responseData = await response.json();
        setSuccessMessage("Quotation Get Successfully !");
        navigate("/rajasthantext", { state: { responseData, formData } });
      } else {
        const errorData = await response.json();
        setError(errorData.message || "Something went wrong");
      }
    } catch (error) {
      setError("Network error occurred");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="form-to-pdf">
      {loading && <Loader />}
      {error && <PopBox message={error} type="error" />}
      {successMessage && <PopBox message={successMessage} type="success" />}
      <h2>Rajasthan Quotation</h2>
      <form onSubmit={handleSubmit}>
        <label>Check-in Date:</label>
        <input type="date" name="checkInDate" value={formData.checkInDate} onChange={handleChange} required />

        <label>Number of Nights:</label>
        <input type="number" name="numberOfNights" value={formData.numberOfNights} onChange={handleChange} min="1" required />

        <label>Check-out Date:</label>
        <input type="date" name="checkOutDate" value={formData.checkOutDate} disabled required />

        <label>Number of Couples:</label>
        <input type="number" name="numberOfCouples" value={formData.numberOfCouples} onChange={handleChange} min="1" required />

        <label>Meal Plan:</label>
        <select name="mealPlan" value={formData.mealPlan} onChange={handleChange} required>
          <option value="">Select</option>
          <option value="MAP">MAP</option>
          <option value="CP">CP</option>
          <option value="CUSTOM">CUSTOM</option>
        </select>

        <label>Extra Adult with Mattress:</label>
        <input type="number" name="extraAdultWithMattress" value={formData.extraAdultWithMattress} onChange={handleChange} min="0" />

        <label>Extra Child with Mattress:</label>
        <input type="number" name="extraChildWithMattress" value={formData.extraChildWithMattress} onChange={handleChange} min="0" />

        <label>Extra Child without Mattress:</label>
        <input type="number" name="extraChildWithoutMattress" value={formData.extraChildWithoutMattress} onChange={handleChange} min="0" />

        <label>Resorts:</label>
        <Select
          isMulti
          options={resortOptions}
          onChange={handleResortChange}
          value={resortOptions.filter((option) => formData.selectedResorts.includes(option.value))}
        />

        <button className="submit-button">Submit</button>
      </form>
    </div>
  );
};

export default AddTripForm;
