import React, { useState } from "react";
import Register from "../components/Register";
import ChangePassword from "../components/ChangePass";
import DeleteUser from "../components/Delete";
import "../../Goa/screen/Tab.css";

const UserTabs = () => {
  const [activeTab, setActiveTab] = useState("register");

  const renderTabContent = () => {
    switch (activeTab) {
      case "register":
        return <Register />;
      case "change-password":
        return <ChangePassword />;
      case "delete-user":
        return <DeleteUser />;
      default:
        return <Register />;
    }
  };

  return (
    <div className="trip-tabs">
      <div className="tab-bar">
        <button
          onClick={() => setActiveTab("register")}
          className={`register ${activeTab === "register" ? "active" : ""}`}
        >
          Register
        </button>
        <button
          onClick={() => setActiveTab("change-password")}
          className={`change-password ${
            activeTab === "change-password" ? "active" : ""
          }`}
        >
          Change Password
        </button>
        <button
          onClick={() => setActiveTab("delete-user")}
          className={`delete-user ${
            activeTab === "delete-user" ? "active" : ""
          }`}
        >
          Delete User
        </button>
      </div>
      <div className="tab-content">{renderTabContent()}</div>
    </div>
  );
};

export default UserTabs;
