import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../../Form/Form.css"; // Ensure this CSS file is created and linked properly

const vehicleOptions = [
  { value: "SMALL CAR UPTO 3 PAX", label: "SMALL CAR UPTO 3 PAX" },
  { value: "2 SMALL CAR", label: "2 SMALL CAR" },
  { value: "ERTIGA (UPTO 6 PAX)", label: "ERTIGA (UPTO 6 PAX)" },
  { value: "WINGER 10-12", label: "WINGER 10-12" },
  { value: "13-17 SEATER", label: "13-17 SEATER" },
  { value: "18-20 SEATER", label: "18-20 SEATER" },
  { value: "25-27 SEATER", label: "25-27 SEATER" },
  { value: "30-35 SEATER", label: "30-35 SEATER" },
  { value: "40 SEATER BUS", label: "40 SEATER BUS" },
];

const typeOptions = [
  { value: "PICKUP", label: "PICKUP" },
  { value: "DROP", label: "DROP" },
];

const placeOptions = [
  { value: "THIVIM RAILWAY STATION", label: "THIVIM RAILWAY STATION" },
  { value: "KARMALI RAILWAY STATION", label: "KARMALI RAILWAY STATION" },
  { value: "MADGAON RAILWAY STATION", label: "MADGAON RAILWAY STATION" },
  { value: "DABOLIM AIRPORT", label: "DABOLIM AIRPORT" },
  { value: "MAPUSA BUS STOP", label: "MAPUSA BUS STOP" },
  { value: "PANJIM BUS STOP", label: "PANJIM BUS STOP" },
  { value: "VASCO RAILWAY STATION", label: "VASCO RAILWAY STATION" },
  { value: "MOPA AIRPORT", label: "MOPA AIRPORT" },
];

const PickupDropForm = () => {
  const [formData, setFormData] = useState({
    vehicle_type: null,
    type: null,
    place: null,
    rate: null,
  });

  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.vehicle_type || !formData.type || !formData.place || !formData.rate) {
      setError("All fields are required!");
      return;
    }

    setError("");
    setSuccessMessage("");

    const apiUrl = process.env.REACT_APP_API_URL; // Replace with your actual API endpoint
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(`${apiUrl}/api/v1/admin/hotel/pickupdrop-rate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Include token if needed
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        const result = await response.json();
        throw new Error(result.message || "Failed to submit pickup/drop data.");
      }

      const result = await response.json();
      setSuccessMessage("Pickup/Drop data submitted successfully!");
      
      // Reset all fields to null
      setFormData({
        vehicle_type: null,
        type: null,
        place: null,
        rate: null,
      });
    } catch (err) {
      setError(err.message || "An error occurred while submitting the form. Please try again.");
    }
  };

  return (
    <div className="form-to-pdf">
      <h2>Update Pickup/Drop</h2>
      <form onSubmit={handleSubmit}>
        <label>Vehicle Type:</label>
        <select
          name="vehicle_type"
          value={formData.vehicle_type || ""}
          onChange={handleChange}
          required
        >
          <option value="">Select</option>
          {vehicleOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>

        <label>Type:</label>
        <select
          name="type"
          value={formData.type || ""}
          onChange={handleChange}
          required
        >
          <option value="">Select</option>
          {typeOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>

        <label>Place:</label>
        <select
          name="place"
          value={formData.place || ""}
          onChange={handleChange}
          required
        >
          <option value="">Select</option>
          {placeOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>

        <label>Rate (INR):</label>
        <input
          type="number"
          name="rate"
          value={formData.rate || ""}
          onChange={handleChange}
          min="0"
          required
        />

        {error && <p className="error-message">{error}</p>}
        {successMessage && <p className="success-message">{successMessage}</p>}

        <button type="submit" className="submit-button">
          Submit
        </button>
      </form>
    </div>
  );
};

export default PickupDropForm;
