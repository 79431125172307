import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from "react-router-dom";
import "./App.css";
import Navbar from "./components/Navbar";
import AdminNavbar from "./components/NavbarAdmin"; 
import Goa from "./Form/GoaForm";
import Rajsthan from "./Form/RajsthanForm";
import TextBox from "./Textbox/textbox";
import RajasthanBox from "./Textbox/RajasthanText";
import LoginBox from "./login/login";
import AdminGoa from "./Admin/Goa/screen/GoaTab";
import AdminRajasthan from "./Admin/Goa/screen/Rajasthan";
import AdminUser from "./Admin/User/screen/UserTab";

// Protected Route Component
const ProtectedRoute = ({ role, requiredRole, children }) => {
  const token = localStorage.getItem("token");
  if (!token || role === null) {
    return <Navigate to="/login" replace />;
  }

  if (role !== requiredRole) {
    return <Navigate to="/" replace />;
  }

  return children;
};

function App() {
  const location = useLocation();
  const token = localStorage.getItem("token");
  const role = token ? parseInt(localStorage.getItem("role"), 10) : null;

  const isAdminRoute = location.pathname.startsWith("/admin");

  return (
    <div className="App">
      {/* Navbar */}
      {location.pathname !== "/login" && (isAdminRoute ? <AdminNavbar /> : <Navbar />)}

      <Routes>
        {/* User routes */}
        <Route
          path="/goa"
          element={
            <ProtectedRoute role={role} requiredRole={0}>
              <Goa />
            </ProtectedRoute>
          }
        />
        <Route
          path="/rajasthan"
          element={
            <ProtectedRoute role={role} requiredRole={0}>
              <Rajsthan />
            </ProtectedRoute>
          }
        />
        <Route
          path="/textbox"
          element={
            <ProtectedRoute role={role} requiredRole={0}>
              <TextBox />
            </ProtectedRoute>
          }
        />
         <Route
          path="/rajasthantext"
          element={
            <ProtectedRoute role={role} requiredRole={0}>
              <RajasthanBox />
            </ProtectedRoute>
          }
        />
        
        {/* Admin routes */}
        <Route
          path="/admin/goa"
          element={
            <ProtectedRoute role={role} requiredRole={1}>
              <AdminGoa />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/rajasthan"
          element={
            <ProtectedRoute role={role} requiredRole={1}>
              <AdminRajasthan />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/user"
          element={
            <ProtectedRoute role={role} requiredRole={1}>
              <AdminUser />
            </ProtectedRoute>
          }
        />

        {/* Login Route */}
        <Route path="/login" element={<LoginBox />} />

        {/* Redirect for unmatched routes */}
        <Route path="*" element={<Navigate to={role === 1 ? "/admin/goa" : "/goa"} replace />} />
      </Routes>
    </div>
  );
}

export default function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}
