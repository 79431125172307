import React, { useState, useEffect } from 'react';
import './Popupbox.css'; // Style for the pop-up box

const PopBox = ({ message, type }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false); // Hide after 3 seconds
    }, 1000);

    return () => clearTimeout(timer); // Clean up the timer
  }, []);

  if (!isVisible) return null;

  return (
    <div className={`popbox ${type}`}>
      <p>{message}</p>
    </div>
  );
};

export default PopBox;
