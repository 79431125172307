import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../../Form/Form.css"; // Ensure this CSS file is created and linked properly

const hotelOptions = [
  { value: "RAMATAN RESORT (VAGATOR) DELUX GARDEN VIEW", label: "RAMATAN RESORT (VAGATOR) DELUX GARDEN VIEW" },
  { value: "RAMATAN RESORT (VAGATOR) SUPER DELUX WITH BALCONEY", label: "RAMATAN RESORT (VAGATOR) SUPER DELUX WITH BALCONEY" },
  { value: "RAMATAN RESORT (VAGATOR) PREMIUM WITH BATH TUB", label: "RAMATAN RESORT (VAGATOR) PREMIUM WITH BATH TUB" },
  { value: "THE OCEAN PARK EXECUTIVE ROOM", label: "THE OCEAN PARK EXECUTIVE ROOM" },
  { value: "THE OCEAN PARK STUDIO ROOM", label: "THE OCEAN PARK STUDIO ROOM" },
  { value: "THE BAGA MARINA STANDARD ROOM", label: "THE BAGA MARINA STANDARD ROOM" },
  { value: "THE BAGA MARINA SUPERIOR DOUBLE", label: "THE BAGA MARINA SUPERIOR DOUBLE" },
  { value: "THE BAGA MARINA DELUXE DOUBLE", label: "THE BAGA MARINA DELUXE DOUBLE" },
  { value: "D ALTRUS GRAND PREMIUM", label: "D ALTRUS GRAND PREMIUM" },
  { value: "MERADAN LA OASIS DELUX ROOM", label: "MERADAN LA OASIS DELUX ROOM" },
  { value: "MERADAN OPUS DELUX ROOM", label: "MERADAN OPUS DELUX ROOM" },
  { value: "CALUNGUT GRAND DELUX ROOM", label: "CALUNGUT GRAND DELUX ROOM" },
  { value: "AMANI VAGATOR DELUX ROOM", label: "AMANI VAGATOR DELUX ROOM" },
  { value: "JOIEA DO MAR PREMIUM ROOM", label: "JOIEA DO MAR PREMIUM ROOM" },
  { value: "19 BELOW CABANA COTTAGE", label: "19 BELOW CABANA COTTAGE" },
  { value: "AMARA GRAND OCEAN FRONT 4* PREMIUM ROOM", label: "AMARA GRAND OCEAN FRONT 4* PREMIUM ROOM" },
  { value: "AMARA GRAND BAGA 4* DELUX", label: "AMARA GRAND BAGA 4* DELUX" },
  { value: "HOTEL GOA CONTINENTAL BAGA DELUXE ROOM", label: "HOTEL GOA CONTINENTAL BAGA DELUXE ROOM" },
  { value: "HOTEL GOA CONTINENTAL BAGA EXECUTIVE ROOM", label: "HOTEL GOA CONTINENTAL BAGA EXECUTIVE ROOM" },
  { value: "HOTEL GOA CONTINENTAL BAGA FAMILY SUITE (4PAX)", label: "HOTEL GOA CONTINENTAL BAGA FAMILY SUITE (4PAX)" },
  { value: "CITRUS CANDOLIM CLASSIC ROOM", label: "CITRUS CANDOLIM CLASSIC ROOM" },
  { value: "SILVER SHELL SUPERIOR ROOM", label: "SILVER SHELL SUPERIOR ROOM" },
  { value: "SILVER SHELL CLASSIC ROOM", label: "SILVER SHELL CLASSIC ROOM" },
  { value: "SILVER SHELL PREMIUM ROOM", label: "SILVER SHELL PREMIUM ROOM" },
  { value: "LILLY WOOD HIGHLAND & BEACH RESORT EXECUTIVE ROOM", label: "LILLY WOOD HIGHLAND & BEACH RESORT EXECUTIVE ROOM" },
  { value: "LILLY WOOD HIGHLAND & BEACH RESORT AMBASSADOR ROOM", label: "LILLY WOOD HIGHLAND & BEACH RESORT AMBASSADOR ROOM" },
  { value: "GOLDEN SANDS DELUX ROOM", label: "GOLDEN SANDS DELUX ROOM" },
  { value: "GOLDEN SANDS PREMIUM ROOM", label: "GOLDEN SANDS PREMIUM ROOM" },


];

const mealPlanOptions = [
  { value: "MAP", label: "MAP" },
  { value: "CP", label: "CP" },
  { value: "CUSTOM", label: "CUSTOM" },
  
];

const HotelForm = () => {
  const [formData, setFormData] = useState({
    hotel_name: null,
    start_date: null,
    end_date: null,
    meal_plan: null,
    rate: null,
    extra_adult_with_mattress: null,
    extra_child_with_mattress: null,
    extra_child_without_mattress: null,
  });

  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.hotel_name || !formData.start_date || !formData.end_date || !formData.meal_plan || !formData.rate) {
      setError("All fields are required!");
      return;
    }

    setError("");
    setSuccessMessage("");

    const apiUrl = process.env.REACT_APP_API_URL; // Replace with your actual API endpoint
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(`${apiUrl}/api/v1/admin/hotel/create-hotel-rate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Include token if needed
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        const result = await response.json();
        throw new Error(result.message || "Failed to submit hotel data.");
      }

      const result = await response.json();
      setSuccessMessage("Hotel data submitted successfully!");
      
      // Reset all fields to null
      setFormData({
        hotel_name: null,
        start_date: null,
        end_date: null,
        meal_plan: null,
        rate: null,
        extra_adult_with_mattress: null,
        extra_child_with_mattress: null,
        extra_child_without_mattress: null,
      });

    } catch (err) {
      setError(err.message || "An error occurred while submitting the form. Please try again.");
    }
  };

  return (
    <div className="form-to-pdf">
      <h2>Update Hotel</h2>
      <form onSubmit={handleSubmit}>
        <label>Hotel Name:</label>
        <select
          name="hotel_name"
          value={formData.hotel_name || ""}
          onChange={handleChange}
          required
        >
          <option value="">Select</option>
          {hotelOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>

        <label>Start Date:</label>
        <input
          type="date"
          name="start_date"
          value={formData.start_date || ""}
          onChange={handleChange}
          required
        />

        <label>End Date:</label>
        <input
          type="date"
          name="end_date"
          value={formData.end_date || ""}
          onChange={handleChange}
          required
        />

        <label>Meal Plan:</label>
        <select
          name="meal_plan"
          value={formData.meal_plan || ""}
          onChange={handleChange}
          required
        >
          <option value="">Select</option>
          {mealPlanOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>

        <label>Rate (INR):</label>
        <input
          type="number"
          name="rate"
          value={formData.rate || ""}
          onChange={handleChange}
          min="0"
          required
        />

        <label>Extra Adult with Mattress Rate (INR):</label>
        <input
          type="number"
          name="extra_adult_with_mattress"
          value={formData.extra_adult_with_mattress || ""}
          onChange={handleChange}
          min="0"
        />

        <label>Extra Child with Mattress Rate (INR):</label>
        <input
          type="number"
          name="extra_child_with_mattress"
          value={formData.extra_child_with_mattress || ""}
          onChange={handleChange}
          min="0"
        />

        <label>Extra Child without Mattress Rate (INR):</label>
        <input
          type="number"
          name="extra_child_without_mattress"
          value={formData.extra_child_without_mattress || ""}
          onChange={handleChange}
          min="0"
        />

        {error && <p className="error-message">{error}</p>}
        {successMessage && <p className="success-message">{successMessage}</p>}

        <button type="submit" className="submit-button">
          Submit
        </button>
      </form>
    </div>
  );
};

export default HotelForm;
