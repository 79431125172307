import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom"; // Access route state
import PopBox from '../components/Popupbox'; // Assuming you've placed PopBox in the same folder
import "./textbox.css";

const SummaryScreen = () => {
  const location = useLocation();
  const { formData } = location.state || {}; // Destructure safely
  const { responseData } = location.state || {};
  const [rates, setRates] = useState([]); // Initialize rates as empty array
  const [loading, setLoading] = useState(true); // Loading state for data
  const [isEditing, setIsEditing] = useState(false); // Toggle for edit mode
  const [summaryText, setSummaryText] = useState(""); // State for summary text
  const [popBoxMessage, setPopBoxMessage] = useState(""); // State for the pop-up message
  const [popBoxType, setPopBoxType] = useState(""); // Type of the pop-up (success, error, info)
  
  const [timeoutId, setTimeoutId] = useState(null); // Store timeout ID to clear it when needed

  const hasMounted = useRef(false); // To track first render


  useEffect(() => {
    if (responseData && responseData.data) {
      setRates(responseData.data); // Populate rates from static data
    }
    setLoading(false); // Data loading completed
  }, []); // Empty dependency array ensures it runs only once

  // Generate default summary text
  const generateSummaryText = () => {
    const quotationDate = (formData?.quotationDate
      ? new Date(formData.quotationDate)
      : new Date()
    )
      .toLocaleDateString("en-GB")
      .replace(/\//g, "-");

    const resortDetails = rates.length
      ? rates
          .map((rateItem) => {
            const resortName = Object.keys(rateItem)[0];
            const details = rateItem[resortName];

            // Dynamically include only non-zero or non-blank values
            const rate =
              details.rate > 0
                ? `Rate: ₹${details.rate} Per Couple`
                : "";
            const extraAdultWithMattress =
              details.extra_adult_with_mattress > 0
                ? `Extra Adult with Mattress: ${details.extra_adult_with_mattress}`
                : "";
            const extraChildWithMattress =
              details.extra_child_with_mattress > 0
                ? `Extra Child with Mattress: ${details.extra_child_with_mattress}`
                : "";
            const extraChildWithoutMattress =
              details.extra_child_without_mattress > 0
                ? `Extra Child without Mattress: ${details.extra_child_without_mattress}`
                : "";

            // Uniform spacing and consistent indentation
            const formattedDetails = [
              `\n${resortName}`,
              rate,
              extraAdultWithMattress,
              extraChildWithMattress,
              extraChildWithoutMattress,
            ]
              .filter(Boolean) // Exclude empty strings
              .map((line) => `  ${line}`) // Add consistent two-space indentation
              .join("\n");

            return `${formattedDetails}\n`; // Add a blank line after each resort
          })
          .join("\n") // Join resorts with a newline
      : "No resorts available";

    const inclusions = [
      " ",
      formData.numberOfNights ? `👉 ${formData.numberOfNights} Nights in AC room` : "",
      formData.numberOfBreakfast && formData.numberOfDinner
        ? `👉 ${formData.numberOfBreakfast} Breakfast, ${formData.numberOfDinner} Dinner`
        : "",
      formData.pickupFrom ? `👉 Pickup from ${formData.pickupFrom}` : "",
      formData.dropTo ? `👉 Drop to ${formData.dropTo}` : "",
      formData.northGoaTour && formData.northGoaTour !== "NO" ? `👉 North Goa Tour ${formData.northGoaTour}` : "",
      formData.southGoaTour && formData.southGoaTour !== "NO" ? `👉 South Goa Tour ${formData.southGoaTour}` : "",
      formData.dudhsagarTour && formData.dudhsagarTour !== "NO" ? `👉 Dudhsagar Tour ${formData.dudhsagarTour}` : "",
      formData.activity1 ? `👉 ${formData.activity1}` : "",
      formData.activity2 ? `👉 ${formData.activity2}` : "",
      formData.activity3 ? `👉 ${formData.activity3}` : "",
    ]
      .filter(Boolean)
      .join("\n");

    return `
    As per your requirements, find rates as below...

    Travel Date : ${quotationDate}
    
    ${formData.numberOfNights} Nights & ${Number(formData.numberOfNights) + 1} Days

    Meal Plan: ${formData?.mealPlan ?? "Not provided"}
    
    Couples: ${formData?.numberOfCouples ?? "Not provided"}

    Resort Details:
    ${resortDetails}

    Inclusion:
    ${inclusions}
    `;
  };

  useEffect(() => {
    if (hasMounted.current) {
      setSummaryText(generateSummaryText()); // Update text dynamically
    } else {
      hasMounted.current = true;
    }
  }, [rates, formData]); // Dependency on rates and formData

  const handleEdit = () => setIsEditing(true);
  const handleSave = () => {
    setIsEditing(false);
    showPopBox("Quotation saved successfully!", "success"); // Show success message
  };
  const handleCopy = () => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(summaryText)
        .then(() => {
          showPopBox("Quotation copied to your clipboard!", "success"); // Show success message
        })
        .catch((error) => {
          console.error("Failed to copy text:", error);
          showPopBox("Error copying text. Please try again.", "error"); // Error message
        });
    } else {
      const textarea = document.createElement("textarea");
      textarea.value = summaryText;
      textarea.style.position = "fixed"; // Avoid scrolling to bottom
      textarea.style.opacity = "0"; // Hide the textarea
      document.body.appendChild(textarea);
      textarea.focus();
      textarea.select();

      try {
        document.execCommand("copy");
        showPopBox("Quotation copied to your clipboard!", "success"); // Show success message
      } catch (error) {
        console.error("Fallback: Failed to copy text:", error);
        showPopBox("Error copying text. Please try again.", "error"); // Error message
      } finally {
        document.body.removeChild(textarea);
      }
    }
  };

  const handleTextChange = (e) => setSummaryText(e.target.value);

  // Function to show pop-up message and clear previous timeout
  const showPopBox = (message, type) => {
    setPopBoxMessage(""); // Clear any previous message immediately
    setPopBoxType(""); // Clear any previous type
    
    setPopBoxMessage(message);
    setPopBoxType(type);

    // Clear any existing timeout before setting a new one
    if (timeoutId) {
      clearTimeout(timeoutId); // Clear the previous timeout
    }

    const newTimeoutId = setTimeout(() => {
      setPopBoxMessage(""); // Hide pop-up after 3 seconds
    }, 3000);

    setTimeoutId(newTimeoutId); // Set the new timeout ID
  };

  return (
    <div className="summary-container">
      <h2 className="summary-title">Quotation Details</h2>
      <div className="summary-box">
        {isEditing ? (
          <textarea
            value={summaryText}
            onChange={handleTextChange}
            className="summary-edit-textarea"
          />
        ) : (
          <pre className="summary-text">{summaryText}</pre>
        )}
      </div>
      <div className="summary-button-group">
        {isEditing ? (
          <button className="summary-button" onClick={handleSave}>
            Save
          </button>
        ) : (
          <button className="summary-button" onClick={handleEdit}>
            Edit
          </button>
        )}
        <button className="summary-button" onClick={handleCopy}>
          Copy
        </button>
      </div>

      {/* Show pop-up message */}
      {popBoxMessage && <PopBox message={popBoxMessage} type={popBoxType} />}
    </div>
  );
};

export default SummaryScreen;
