import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../../Form/Form.css"; // Ensure this CSS file is created and linked properly

const SightseeingForm = () => {
  const [formData, setFormData] = useState({
    vehicleType: "",
    place: "",
    type: "",
    rate: "",
  });

  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.vehicleType || !formData.place || !formData.type || !formData.rate) {
      setError("All fields are required!");
      return;
    }

    setError("");
    setSuccessMessage("");

    const apiUrl = process.env.REACT_APP_API_URL; // Replace with your actual API endpoint
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(`${apiUrl}/api/v1/admin/hotel/sightseeing-rate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Include token if needed
        },
        body: JSON.stringify({
          vehicle_type: formData.vehicleType,
          place: formData.place,
          type: formData.type,
          rate: formData.rate,
        }),
      });

      if (!response.ok) {
        const result = await response.json();
        throw new Error(result.message || "Failed to submit sightseeing data.");
      }

      const result = await response.json();
      setSuccessMessage("Sightseeing data submitted successfully!");

      // Reset all fields to empty strings
      setFormData({
        vehicleType: "",
        place: "",
        type: "",
        rate: "",
      });

    } catch (err) {
      setError(err.message || "An error occurred while submitting the form. Please try again.");
    }
  };

  return (
    <div className="form-to-pdf">
      <h2>Update Sightseeing</h2>
      <form onSubmit={handleSubmit}>
        <label>Vehicle Type:</label>
        <select
          name="vehicleType"
          value={formData.vehicleType}
          onChange={handleChange}
          required
        >
          <option value="">Select</option>
          <option value="SMALL CAR UPTO 3 PAX">SMALL CAR UPTO 3 PAX</option>
          <option value="2 SMALL CAR">2 SMALL CAR</option>
          <option value="ERTIGA (UPTO 6 PAX)">ERTIGA (UPTO 6 PAX)</option>
          <option value="WINGER 10-12">WINGER 10-12</option>
          <option value="13-17 SEATER">13-17 SEATER</option>
          <option value="18-20 SEATER">18-20 SEATER</option>
          <option value="25-27 SEATER">25-27 SEATER</option>
          <option value="30-35 SEATER">30-35 SEATER</option>
          <option value="40 SEATER BUS">40 SEATER BUS</option>
        </select>

        <label>Place:</label>
        <select
          name="place"
          value={formData.place}
          onChange={handleChange}
          required
        >
          <option value="">Select</option>
          <option value="NORTH GOA SIGHTSEEN">NORTH GOA SIGHTSEEN</option>
          <option value="SOUTH GOA SIGHTSEEN">SOUTH GOA SIGHTSEEN</option>
          <option value="DUDHSAGAR SIGHTSEEN">DUDHSAGAR SIGHTSEEN</option>
        </select>

        <label>Type:</label>
        <select
          name="type"
          value={formData.type}
          onChange={handleChange}
          required
        >
          <option value="">Select</option>
          <option value="PVT">PVT</option>
          <option value="SIC">SIC</option>
        </select>

        <label>Rate (INR):</label>
        <input
          type="number"
          name="rate"
          value={formData.rate}
          onChange={handleChange}
          min="0"
          required
        />

        {error && <p className="error-message">{error}</p>}
        {successMessage && <p className="success-message">{successMessage}</p>}

        <button type="submit" className="submit-button">
          Submit
        </button>
      </form>
    </div>
  );
};

export default SightseeingForm;
