import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../../Form/Form.css"; // Ensure this CSS file is created and linked properly

const hotelOptions = [
  { value: "THE ANANTA UDAIPUR LUXURY COTTAGE", label: "THE ANANTA UDAIPUR LUXURY COTTAGE" },
  { value: "THE ANANTA UDAIPUR JUNGLE WOODEN COTTAGE", label: "THE ANANTA UDAIPUR JUNGLE WOODEN COTTAGE" },
  { value: "UDAIPUR LAKEND DELUX CITY VIEW", label: "UDAIPUR LAKEND DELUX CITY VIEW" },
  { value: "UDAIPUR LAKEND DELUX LAKE VIEW", label: "UDAIPUR LAKEND DELUX LAKE VIEW" },
  { value: "ANANDAM RESORT PREMIER ROOM", label: "ANANDAM RESORT PREMIER ROOM" },
  { value: "ANANDAM RESORT HILL VIEW SUITE BALCONY", label: "ANANDAM RESORT HILL VIEW SUITE BALCONY" },
  { value: "ANANDAM RESORT HILL VIEW JACUZZI SUITE WITH PRIVATE POOL", label: "ANANDAM RESORT HILL VIEW JACUZZI SUITE WITH PRIVATE POOL" },
  { value: "ANANDAM RESORT HILL VIEW ANANDAM POOL VILLA JACUZZI", label: "ANANDAM RESORT HILL VIEW ANANDAM POOL VILLA JACUZZI" },
  { value: "ROOP GHAR RESORT & SPA PREMIUM", label: "ROOP GHAR RESORT & SPA PREMIUM" },
  { value: "UDAIPUR HOWARD JOHNSON BY WYNDHAM DELUXE ROOM", label: "UDAIPUR HOWARD JOHNSON BY WYNDHAM DELUXE ROOM" },
  { value: "UDAIPUR HOWARD JOHNSON BY WYNDHAM PREMIUM", label: "UDAIPUR HOWARD JOHNSON BY WYNDHAM PREMIUM" },
  { value: "HUNTING LODGE PREMIUM", label: "HUNTING LODGE PREMIUM" },
  { value: "PANDORA DELUXE ROOM", label: "PANDORA DELUXE ROOM" },
  { value: "TURBAN HOTELS SUPERIOR ROOM", label: "TURBAN HOTELS SUPERIOR ROOM" },
  { value: "TURBAN HOTELS TURBAN ROOM", label: "TURBAN HOTELS TURBAN ROOM" },
  { value: "TURBAN HOTELS DELUXE ROOM", label: "TURBAN HOTELS DELUXE ROOM" },
  { value: "TURBAN HOTELS PREMIUM ROOM", label: "TURBAN HOTELS PREMIUM ROOM" },
  { value: "TURBAN HOTELS EXECUTIVE ROOM", label: "TURBAN HOTELS EXECUTIVE ROOM" },
  { value: "TURBAN HOTELS SUITE", label: "TURBAN HOTELS SUITE" },
  { value: "TURBAN HOTELS LUXURY COTTAGE", label: "TURBAN HOTELS LUXURY COTTAGE" },
  { value: "TURBAN HOTELS LUXURY COTTAGE WITH JACUZZI", label: "TURBAN HOTELS LUXURY COTTAGE WITH JACUZZI" },
  { value: "TURBAN HOTELS LUXURY COTTAGE WITH PLUNGE POOL", label: "TURBAN HOTELS LUXURY COTTAGE WITH PLUNGE POOL" },
  { value: "G MOUNT VALLEY DELUXE ROOM", label: "G MOUNT VALLEY DELUXE ROOM" },
  { value: "THE BODHI DELUXE ROOM", label: "THE BODHI DELUXE ROOM" },
  { value: "LEMON TREE SUPERIOR", label: "LEMON TREE SUPERIOR" },
  { value: "SAJJANBAGH KUMBHALGARH ARAVALLI VIEW", label: "SAJJANBAGH KUMBHALGARH ARAVALLI VIEW" },
  { value: "MAHUA BAGH DELUXE ROOM", label: "MAHUA BAGH DELUXE ROOM" },
  { value: "MAHUA BAGH PREMIUM ROOM", label: "MAHUA BAGH PREMIUM ROOM" },
  { value: "MAHUA BAGH SUPER DELUXE ROOM", label: "MAHUA BAGH SUPER DELUXE ROOM" },
  { value: "MAHUA BAGH MAHUA BAGH COTTAGE", label: "MAHUA BAGH MAHUA BAGH COTTAGE" },
  { value: "MAHUA BAGH ARAVALI COTTAGE", label: "MAHUA BAGH ARAVALI COTTAGE" },
  { value: "MAHUA BAGH MAHUA BAGH VISTA", label: "MAHUA BAGH MAHUA BAGH VISTA" },
  { value: "MAHUA BAGH POOL VILLA COTTAGE", label: "MAHUA BAGH POOL VILLA COTTAGE" },
  { value: "MAHUA BAGH HONEYMOON COTTAGE", label: "MAHUA BAGH HONEYMOON COTTAGE" },
  { value: "KANANWAS PREMIUM COTTAGE", label: "KANANWAS PREMIUM COTTAGE" },
  { value: "KANANWAS LUXURY COTTAGE", label: "KANANWAS LUXURY COTTAGE" },
  { value: "KANANWAS SWEETHEART COTTAGE", label: "KANANWAS SWEETHEART COTTAGE" },
  { value: "KANANWAS PLUNGE POOL COTTAGE", label: "KANANWAS PLUNGE POOL COTTAGE" },
  { value: "KANANWAS PRESIDENTIAL SUITE", label: "KANANWAS PRESIDENTIAL SUITE" },
  { value: "RENEST HERITAGE ROOM (7 ROOMS)", label: "RENEST HERITAGE ROOM (7 ROOMS)" },
  { value: "RENEST GRAND HERITAGE ROOM (4 ROOMS)", label: "RENEST GRAND HERITAGE ROOM (4 ROOMS)" },
  { value: "RENEST MAJESTIC ROOM (5 ROOMS)", label: "RENEST MAJESTIC ROOM (5 ROOMS)" },
  { value: "RENEST REGAL ROOM (6 ROOMS)", label: "RENEST REGAL ROOM (6 ROOMS)" },
  { value: "RENEST ARAVALI SUITE (6 ROOMS)", label: "RENEST ARAVALI SUITE (6 ROOMS)" },
  { value: "RENEST ROYAL SUITE (2 ROOMS)", label: "RENEST ROYAL SUITE (2 ROOMS)" },
  { value: "RENEST MAHARAJA SUITE (4 ROOMS)", label: "RENEST MAHARAJA SUITE (4 ROOMS)" },
  { value: "THE EVEREST HILL RESORT", label: "THE EVEREST HILL RESORT" },
];

const mealPlanOptions = [
  { value: "MAP", label: "MAP" },
  { value: "CP", label: "CP" },
  { value: "CUSTOM", label: "CUSTOM" },
  
];

const HotelForm = () => {
  const [formData, setFormData] = useState({
    hotel_name: null,
    start_date: null,
    end_date: null,
    meal_plan: null,
    rate: null,
    extra_adult_with_mattress: null,
    extra_child_with_mattress: null,
    extra_child_without_mattress: null,
  });

  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.hotel_name || !formData.start_date || !formData.end_date || !formData.meal_plan || !formData.rate) {
      setError("All fields are required!");
      return;
    }

    setError("");
    setSuccessMessage("");

    const apiUrl = process.env.REACT_APP_API_URL; // Replace with your actual API endpoint
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(`${apiUrl}/api/v1/admin/hotel/rajasthan-create-hotel-rate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Include token if needed
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        const result = await response.json();
        throw new Error(result.message || "Failed to submit hotel data.");
      }

      const result = await response.json();
      setSuccessMessage("Hotel data submitted successfully!");
      
      // Reset all fields to null
      setFormData({
        hotel_name: null,
        start_date: null,
        end_date: null,
        meal_plan: null,
        rate: null,
        extra_adult_with_mattress: null,
        extra_child_with_mattress: null,
        extra_child_without_mattress: null,
      });

    } catch (err) {
      setError(err.message || "An error occurred while submitting the form. Please try again.");
    }
  };

  return (
    <div className="form-to-pdf">
      <h2>Update Hotel</h2>
      <form onSubmit={handleSubmit}>
        <label>Hotel Name:</label>
        <select
          name="hotel_name"
          value={formData.hotel_name || ""}
          onChange={handleChange}
          required
        >
          <option value="">Select</option>
          {hotelOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>

        <label>Start Date:</label>
        <input
          type="date"
          name="start_date"
          value={formData.start_date || ""}
          onChange={handleChange}
          required
        />

        <label>End Date:</label>
        <input
          type="date"
          name="end_date"
          value={formData.end_date || ""}
          onChange={handleChange}
          required
        />

        <label>Meal Plan:</label>
        <select
          name="meal_plan"
          value={formData.meal_plan || ""}
          onChange={handleChange}
          required
        >
          <option value="">Select</option>
          {mealPlanOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>

        <label>Rate (INR):</label>
        <input
          type="number"
          name="rate"
          value={formData.rate || ""}
          onChange={handleChange}
          min="0"
          required
        />

        <label>Extra Adult with Mattress Rate (INR):</label>
        <input
          type="number"
          name="extra_adult_with_mattress"
          value={formData.extra_adult_with_mattress || ""}
          onChange={handleChange}
          min="0"
        />

        <label>Extra Child with Mattress Rate (INR):</label>
        <input
          type="number"
          name="extra_child_with_mattress"
          value={formData.extra_child_with_mattress || ""}
          onChange={handleChange}
          min="0"
        />

        <label>Extra Child without Mattress Rate (INR):</label>
        <input
          type="number"
          name="extra_child_without_mattress"
          value={formData.extra_child_without_mattress || ""}
          onChange={handleChange}
          min="0"
        />

        {error && <p className="error-message">{error}</p>}
        {successMessage && <p className="success-message">{successMessage}</p>}

        <button type="submit" className="submit-button">
          Submit
        </button>
      </form>
    </div>
  );
};

export default HotelForm;
