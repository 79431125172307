import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../../Form/Form.css"; // Ensure this CSS file is created and linked properly
const activityOptions = [
  { value: "ADVENTURE PARTY BOAT", label: "ADVENTURE PARTY BOAT" },
  { value: "DINNER CRUISE", label: "DINNER CRUISE" },
  { value: "SCUBA WITH WATERSPORT AT GRAND ISLAND(SIC)", label: "SCUBA WITH WATERSPORT AT GRAND ISLAND(SIC)" },
  { value: "SCUBA WITH WATERSPORT AT MALWAN(SIC)", label: "SCUBA WITH WATERSPORT AT MALWAN(SIC)" },
  { value: "SCUBA WITH WATERSPORT AT PARADISE BEACH(SIC)", label: "SCUBA WITH WATERSPORT AT PARADISE BEACH(SIC)" },
];
const ActivityForm = () => {
  const [formData, setFormData] = useState({
    activity: "",
    rate: "",
  });

  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.activity || !formData.rate) {
      setError("All fields are required!");
      return;
    }

    setError("");
    setSuccessMessage("");

    const apiUrl = process.env.REACT_APP_API_URL; // Replace with your actual API endpoint
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(`${apiUrl}/api/v1/admin/hotel/activity-rate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Include token if required
        },
        body: JSON.stringify({
          activity_name: formData.activity,
          rate: formData.rate,
        }),
      });

      if (!response.ok) {
        const result = await response.json();
        throw new Error(result.message || "Failed to submit activity data.");
      }

      const result = await response.json();
      setSuccessMessage("Activity data submitted successfully!");

      // Reset all fields to empty strings
      setFormData({
        activity: "",
        rate: "",
      });
    } catch (err) {
      setError(err.message || "An error occurred while submitting the form. Please try again.");
    }
  };

  return (
    <div className="form-to-pdf">
      <h2>Update Activity</h2>
      <form onSubmit={handleSubmit}>
        <label>Activity:</label>
        <select
          name="activity"
          value={formData.activity}
          onChange={handleChange}
          required
        >
          <option value="">Select</option>
          {activityOptions.map((option) => (
    <option key={option.value} value={option.value}>
      {option.label}
    </option>
  ))}
        </select>

        <label>Rate (INR):</label>
        <input
          type="number"
          name="rate"
          value={formData.rate}
          onChange={handleChange}
          min="0"
          required
        />

        {error && <p className="error-message">{error}</p>}
        {successMessage && <p className="success-message">{successMessage}</p>}

        <button type="submit" className="submit-button">
          Submit
        </button>
      </form>
    </div>
  );
};

export default ActivityForm;
