import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faBars } from '@fortawesome/free-solid-svg-icons'; // Import necessary icons
import './Navbar.css';
import logo from '../../src/assets/rajdarshan.png'; // Import your logo
import { useNavigate } from "react-router-dom";
const Navbar = () => {
  const [showCategories, setShowCategories] = useState(false);
  const categoriesRef = useRef(null); // Ref for categories modal

  const toggleCategories = () => {
    setShowCategories(!showCategories);
  };

  const handleCategoryClick = () => {
    setShowCategories(false); // Close modal on category selection
  };
  
  // Handle closing the modal if clicking outside
  const handleClickOutside = (event) => {
    if (categoriesRef.current && !categoriesRef.current.contains(event.target)) {
      setShowCategories(false);
    }
  };

  useEffect(() => {
    if (showCategories) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showCategories]);
  const navigate = useNavigate();
  const handleLogout = () => {
    // Clear the token from localStorage
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    // Redirect to the login page
    navigate("/login"); // Replace with your actual login route
    console.log("Logged out successfully.");
  };

  return (
    <>
      {/* Desktop View */}
      <nav className="navbar">
        <div className="navbar-brand">
          <img src={logo} alt="SKY HOLIDAYS Logo" className="navbar-logo" />
          <span className="navbar-title">RAJDARSHAN TOURISM</span>
        </div>
        <div className="navbar-center">
          <ul className="navbar-options">
            <li><Link to="/goa" onClick={handleCategoryClick}>Goa</Link></li>
            <li><Link to="/rajasthan" onClick={handleCategoryClick}>Rajasthan</Link></li>
          </ul>
        </div>
        <div className="navbar-logout">
          <button onClick={handleLogout} className="logout-btn">
            <FontAwesomeIcon icon={faSignOutAlt} /> Logout
          </button>
        </div>
      </nav>

      {/* Mobile View */}
      <div className="navbar-mobile">
        <div className="navbar-brand-mobile">
          <img src={logo} alt="SKY HOLIDAYS Logo" className="navbar-logo" />
          <span className="navbar-title">RAJDARSHAN TOURISM</span>
        </div>
        <button className="categories-btn" onClick={toggleCategories}>
          <FontAwesomeIcon icon={faBars} />
        </button>

        {/* Left-aligned Modal in mobile view */}
        {showCategories && (
          <div className="categories-modal" ref={categoriesRef}>
            <div className="categories-content">
              <ul>
                <li><Link to="/goa" onClick={handleCategoryClick}>Goa</Link></li>
                <li><Link to="/rajasthan" onClick={handleCategoryClick}>Rajasthan</Link></li>
                <li>
                  <button onClick={handleLogout} className="logout-btn">
                    <FontAwesomeIcon icon={faSignOutAlt} /> Logout
                  </button>
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Navbar;
