import React, { useState } from 'react';
import './login.css'; // Import the CSS file
import { useNavigate } from 'react-router-dom';

const LoginBox = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState(''); // State for messages
  const [isError, setIsError] = useState(false); // State to determine if the message is an error
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // API URL from environment variables
    const apiUrl = process.env.REACT_APP_API_URL;

    try {
      // API call to login
      const response = await fetch(`${apiUrl}/api/v1/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }), // Send email and password as JSON
      });

      if (!response.ok) {
        throw new Error('Login failed. Please check your credentials.');
      }

      const result = await response.json();

      if (result.success) {
        // Store the token in localStorage
        localStorage.setItem('token', result.data.token);
        localStorage.setItem('role', result.data.role);
        // Check the role from the API response
        const role = result.data.role; // Assuming `role` is part of the `user` object in the response

        if (role === "1") {
          setMessage('Login Successful! Redirecting to admin...');
          setIsError(false);
          setTimeout(() => {
            navigate('/admin/goa'); // Redirect to /admin/goa for role 1 (admin)
          }, 1000); // Delay redirection for user feedback
        } else if (role === "0") {
          setMessage('Login Successful!');
          setIsError(false);
          setTimeout(() => {
            navigate('/goa'); // Redirect to /goa for role 0 (user)
          }, 1000); // Delay redirection for user feedback
        } else {
          setMessage('Login Successful, but no valid role found.');
          setIsError(true);
        }
      } else {
        // Handle unsuccessful login
        setMessage(result.message || 'Login failed. Please try again.');
        setIsError(true);
      }
    } catch (error) {
      // Handle error from API request
      setMessage(error.message || 'An error occurred. Please try again later.');
      setIsError(true);
    }
  };

  return (
    <div className="login-box">
      <h2>Rajdarshan Tourism</h2>
      <h2>Login</h2>
      {message && (
        <div className={`message-box ${isError ? 'error' : 'success'}`}>
          {message}
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <div className="input-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="input-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        {/* Submit button remains active regardless of input */}
        <button type="submit" className="submit-btn">Login</button>
      </form>
    </div>
  );
};

export default LoginBox;
