import React, { useState } from 'react';
import Hotel from '../components/Hotel';
import Sightseeing from '../components/Sightseen';
import Activity from '../components/Activity';
import PickupDrop from '../components/PickupDrop';
import './Tab.css';

const TripTabs = () => {
  const [activeTab, setActiveTab] = useState('hotel');

  const renderTabContent = () => {
    switch (activeTab) {
      case 'hotel':
        return <Hotel />;
      case 'sightseeing':
        return <Sightseeing />;
      case 'activity':
        return <Activity />;
      case 'pickup-drop':
        return <PickupDrop />;
      default:
        return <Hotel />;
    }
  };

  return (
    <div className="trip-tabs">
      <div className="tab-bar">
        <button
          onClick={() => setActiveTab('hotel')}
          className={`hotel ${activeTab === 'hotel' ? 'active' : ''}`}
        >
          Hotel
        </button>
        <button
          onClick={() => setActiveTab('sightseeing')}
          className={`sightseeing ${activeTab === 'sightseeing' ? 'active' : ''}`}
        >
          Sightseeing
        </button>
        <button
          onClick={() => setActiveTab('activity')}
          className={`activity ${activeTab === 'activity' ? 'active' : ''}`}
        >
          Activity
        </button>
        <button
          onClick={() => setActiveTab('pickup-drop')}
          className={`pickup-drop ${activeTab === 'pickup-drop' ? 'active' : ''}`}
        >
          Pickup Drop
        </button>
      </div>
      <div className="tab-content">{renderTabContent()}</div>
    </div>
  );
};

export default TripTabs;
