import React, { useState } from "react";
import "../../../Form/Form.css"; // Ensure this CSS file is created and linked properly

const ChangePasswordForm = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmPassword: "", // For validation only
  });

  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState(""); // Success message state

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate if passwords match
    if (formData.password !== formData.confirmPassword) {
      setError("Passwords do not match!");
      setSuccessMessage(""); // Clear success message if there's an error
      return;
    }

    setError("");
    setSuccessMessage(""); // Reset success message before API call

    const apiUrl = process.env.REACT_APP_API_URL; // API URL from environment variables

    try {
      // Prepare the data to send (excluding confirmPassword)
      const { confirmPassword, ...passwordData } = formData;

      // API call using fetch to update the password using PUT
      const response = await fetch(`${apiUrl}/api/v1/auth/change-password`, {
        method: 'PUT', // Change method to PUT for updating password
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`, // Add token to the Authorization header if needed
        },
        body: JSON.stringify(passwordData), // Send email and password only
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();

      if (result.success) {
        setSuccessMessage("Password changed successfully!");
        // Reset form data after successful password change
        setFormData({
          email: "",
          password: "",
          confirmPassword: "", // Reset confirmPassword as well
        });
      } else {
        setError(result.message || "An error occurred while changing password.");
      }
    } catch (err) {
      setError("An error occurred during password change. Please try again.");
      console.error("Change password error:", err);
    }
  };

  return (
    <div className="form-to-pdf">
      <h2>Change Password</h2>
      <form onSubmit={handleSubmit}>
        <label>Email:</label>
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />

        <label>Current Password:</label>
        <input
          type="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          required
        />

        <label>Confirm New Password:</label>
        <input
          type="password"
          name="confirmPassword"
          value={formData.confirmPassword}
          onChange={handleChange}
          required
        />

        {error && <p className="error-message">{error}</p>}
        {successMessage && <p className="success-message">{successMessage}</p>} {/* Display success message */}

        <button type="submit" className="submit-button">
          Change Password
        </button>
      </form>
    </div>
  );
};

export default ChangePasswordForm;
